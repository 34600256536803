body {
  margin: 0;
  background-color: #111111;
  font-family: inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family:  source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.ant-drawer-content-wrapper {
  width: 500px !important;
}

.ant-spin-dot-item {
  background-color: #ff5f00 !important;
}


p, h1, h2, h3, h4, h5, h6  {
  margin: 0px !important;
}
.active-row {
  background-color: #ff5f00;
}

h1 {
  font-size: 48px;
  line-height: 1.2em;
  letter-spacing: -0.02em;
  color: #f9f9f9;
}


h2 {
  font-size: 32px;
  font-weight: semi-bold;
  line-height: 120%;
  letter-spacing: -0.03em;
  color: #f9f9f9;
}

h3 {
  font-size: 19px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #f9f9f9;
}

h4 {
  line-height: 115%;
  font-weight: 600;
  letter-spacing: -0.01em;
  color: #f9f9f9;
  
}

h5 {
  line-height: 120%;
  font-weight: 500; 
  font-size: 10px;
  text-transform: uppercase;
  color: #f9f9f9;
}


p {
  font-size: 15px;
  line-height: 120%;
  letter-spacing: -0.02em;
  color: #f9f9f9;
}

.searchBox input::placeholder, .ant-input::placeholder, .ant-select-selection-placeholder {
  color: #f9f9f975 !important; 
}



p.small, p.sub {
  font-size: 14px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #9f9f9f;
}

p.large {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  color: #808080;
}


.navItem {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none !important;
  gap: 4px;
  padding: 8px 12px 8px 0px;
  border-radius: 12px;
  opacity: 0.5;
}




.navItem:hover {
  opacity: 1;
}

.navItem p {
  font-size: 16px;
  font-weight: 500;
  color: #F9F9F9;
}

.navItem span {
  text-decoration: none !important; 
  color: #F9F9F9;
   }

.activeNavItem {
  opacity: 1;
  
  
}

.activeNavItem p {
  color: #ff5f00;
  font-weight: 500;
}
.rotate-icon {
    transform: rotate(45deg);
}

.submissionCount {
  background-color: #000;
  border: 2px solid #2A2A2C;
  height: 36px;
  max-height: 36px;
  gap: 8px;
  width: 80px;
  border-radius: 8px;
}

.submissionCount img {
  width: 16px;
  margin-left: 15px;
}

.submissionCount p {
  color: #ff5f00;
  font-size: 14px;
  font-weight: 500;
}

.avatar {
  background-color: #ff5f00;
  border: 1px solid #ff5f0050;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

}

.avatar p {
  color: #FFF2d2;
  font-weight: 600;
  padding-right: 1px;
}

.link {
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.3px;
  color: #ff5f00;
  font-weight: 600;
}

.link.password {
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #ff5f00;
  font-weight: 600;
}

.link:hover {
  color: #ff5f00;
  font-weight: 600;
}

.backToForms {
  cursor: pointer;
  opacity: 0.5;
}
.backToForms:hover {
  cursor: pointer;
  opacity: 1;
}

.bold {
  font-weight: 500;
}

.required {
  color: #ff0020;
  font-size: 14px;
}

.button-icon {
  width: 14px;
  height: 14px;
}

.navbar .logo {
  
  height: 20px;
  user-select: none;
}

.signup .logo {
  width: 120px;
  user-select: none;
  margin-bottom: 60px;
}

.signupBox {
  flex: 0 1 400px;
}

.signupInput {
  flex: 1 0 auto;
}

.joris {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  object-fit: cover;
}
.forms .ant-table-row {
  cursor: pointer !important;
}

.ql-toolbar.ql-snow, .ql-container.ql-snow {
  border: 1px solid #2A2A2C !important; 
}

.ant-input, .ant-select, .ant-input-password {
 background-color: #0A0A0A !important;
 border-radius: 8px !important;
 border: 1px solid #2A2A2C;
 color: #f9f9f9 !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #0A0A0A !important;
}

.ant-tooltip-inner {
  background-color: #0A0A0A !important;
  border-radius: 8px !important;
 border: 1px solid #2A2A2C;
}

.ant-tooltip-inner span {
  color: #f9f9f9!important;
}

.ant-btn {
  box-shadow: none !important;
}

.ant-btn-default {
  box-shadow: none !important;
  background-color: #0A0A0A;
  border-radius: 8px !important;
  border: 1px solid #2A2A2C;
}

.ant-checkbox-inner {
  border-color: #f9f9f930 !important;
  background-color: #212121 !important;
}
.ant-dropdown-menu {
  background-color: #0A0A0A;
  border-radius: 8px !important;
  border: 1px solid #2A2A2C;
}

.ant-table-row-expand-icon-cell {
  width: 0px;
  padding: 0;
  margin: 0;
  display: none;
}

:where(.css-dev-only-do-not-override-1ftt6zp).ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >th, :where(.css-dev-only-do-not-override-1ftt6zp).ant-table-wrapper .ant-table-tbody >tr.ant-table-row-selected >td, .ant-table-tbody >tr.ant-table-row-selected >td {
background: #f9f9f910 !important;
}

.email-column {
  width: 40%;
}

.other-column {
  width: 30%;
}

.ant-table-selection-column {
  width: unset !important;
}


.expandedRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2-column layout */
  gap: 16px; /* Space between grid items */
  padding: 8px 36px;
}

.expandedRow ul {
  list-style-type: none; /* Removes bullets */
  padding: 0; /* Removes padding */
  margin: 0; /* Removes margin */
}

.expandedRow li {
  padding: 0; /* Removes padding */
  margin: 0; /* Removes margin */
}


.expandedRow .key {
  padding-bottom: 8px;
  font-weight: 500;
  color: #f9f9f9;
  opacity: 0.5;
  user-select: none;
}

.ant-table-expanded-row {
  background-color: #111111;
  border-bottom: 1px solid #f9f9f910 !important;
}

.ant-table-expanded-row .ant-table-cell {
  background: unset !important;

}

.expandedRow .key::first-letter {
  text-transform: capitalize;
}


.expandedRow p.value, .expandedRow li.value {
  font-size: 15px;
  padding: 4px 8px;
  border-radius: 8px;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  width: fit-content;
  user-select: none;
  margin-bottom: 4px;
  cursor: pointer;
  color: #f9f9f9
}

.rowDir {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.colDir {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  align-items: stretch;
}

.newFormOnboarding {
  display: flex;
  flex-wrap: wrap; /* Allow wrapping as necessary */
  gap: 16px; /* Adjust the space between items */
  align-items: stretch;
  justify-content: center; /* Center items horizontally */
  width: 100%; /* Ensure full width */
  
}

.framerComponent, .getStarted {
  margin-top: 24px;
  padding: 24px;
  box-sizing: border-box; /* Include padding in the element's total width and height */
  border: 1px solid #2A2A2C;
  background-color: #0A0A0A;
  border-radius: 12px;
}

.framerComponent {
  flex: 2; /* Allows the template section to take up ~2/3 of the space */
}

.getStarted {
  flex: 1; /* Allows the get started section to take up ~1/3 of the space */
}



.getStarted img {
  width: 100%;
  border-radius: 8px;
}

pre {
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
}

code {
  font-family: monospace;
  padding: 0 .2em;
}


.searchBox {
  width: 180px;
  max-height: 40px;
  border: 1px solid #2A2A2C;
  background-color: #111111 !important;
  border-radius: 8px;
  padding: 0px 16px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  
}

.actionModal {
  display: flex;
  flex-direction: row;
  gap: 8px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding: 12px;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  border-radius: 8px;
  
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  gap: 12px;

}





.ant-pagination {
  justify-content: flex-start !important;
}

.forms {
  width: 100%;
  max-width: 1200px;

}

.forms .formHeader {
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 16px 12px;
  
  
  user-select: none;
  cursor: unset;
}

.forms .formRow {
  background-color: #141414;
  border: 1px solid #2A2A2C;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-start;
  border-radius: 8px;
}

.forms .formRow:hover {
  background-color: #000;

}

.editButton {
  margin-top: 8px;
  margin-left: 12px;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  gap: 8px;
}

.editButton p {
  font-size: 10px;
}

.checkmark {
  width: 14px;
  height: 14px;
  border: 1px solid #2A2A2C;
  border-radius: 4px;
}

.user {
  width: 18px;
  height: 18px;
}


.forms .formRow p {
  user-select: none;
  color: #f9f9f990;
  font-size: 14px;
  letter-spacing: -0.01em;
  font-weight: 500;
}

.forms .formRow .data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 4px;
  flex-grow: 1;
  
}

.drop-target {
  background-color: #ff5f0050 !important; /* Example: Light background to indicate the drop target */
  border: 2px dashed #000 !important; /* Example: Dashed border around the drop target */
}


.forms .formRow .data.title {
 width: 420px;
 max-width: 420px;
}

.clickable-row {
  cursor: pointer;
}


.forms .formRow .area4, .forms .formHeader .area4 {
  width: 40%;
  }

.forms .formRow .area25, .forms .formHeader .area25 {
  width: 25%;
  }

.forms .formRow .area1, .forms .formHeader .area1 {
  display: flex;
  align-items: center;
  width: 10%;
  }


.forms .formRow .area25 p.formId {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background-color: #808080;
  user-select: none;
  width: fit-content;
  font-weight: 500;
  }


.download {

  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
}

.download img {
  width: 16px;
  height: 16px;
}

.onboarding-step {
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
  align-items: flex-start;
  border-radius: 8px;
}


.unreadCountActive {
  padding: 4px 8px;
  border-radius: 12px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #000;
  border: 1px solid #2A2A2C;
  user-select: none;
}

.unreadCountActive span {
  color: #ff5f00;
  font-size: 8px;
  text-transform: uppercase;
  line-height: 100%;
  font-weight: 600;
  
}



.settings {
  width: 100%;
  max-width: 1200px;
}


.settings .row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #2A2A2C;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 24px;
}

.recipients {
  margin-top: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  width: fit-content;
  gap: 8px;
  padding: 2px 8px;
  border-radius: 4px;
  user-select: none;
}

.recipients .remove {
  cursor: pointer;
}

.recipients:hover {
  background-color: #000;
}

.gap4 {
  padding-bottom: 4px;
}


.integration, .grid-item {
  border-radius: 10px;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  display: flex;
  flex-direction: column; 
  justify-content: space-between;
  user-select: none;
   
}


.logo_integration {
 padding: 24px;
}


.integration img {
  height:96px;
  margin-bottom: 12px;
  
}

.integration .top {
  padding: 16px;
  padding-bottom: 0px;
  
}

.integration .bottom {
  padding: 0px 16px;
  border: 1px solid #2A2A2C;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 57px;
}

.integration .bottom .actions {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.integration .buttons {
  cursor: pointer;
  height: 40px;
  border: 1px solid #2A2A2C;
  border-radius: 6px;
  display: flex;
  
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding: 0px 12px;
}


.integration .buttons:hover {
  background-color: #000;
}


.authMessage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding-bottom: 12px;
  
  margin-bottom: 40px;
  gap: 12px;
}

.noDataIntegration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 12px;
  gap: 12px;
  border: 1px solid #2A2A2C;
  background-color: #ff002010;
  border-radius: 6px;
  border: 1px solid #ff0020;
}

.pricing {
 display: flex;
 flex-direction: column;
 width: 100%;
 max-width: 1200px;
 align-items: flex-start;
  justify-content: center;  
  
}


.pricing .container {
  margin-top: 16px;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  align-items: stretch; 
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.pricing .column {
  flex: 1;
  border-radius: 20px;
  padding: 12px;
  display: flex;
  width: -webkit-fill-available;
  flex-direction: column;
  align-items: flex-start;
  
  
  background-color: #111111;
  border: 1px solid #2A2A2C;
}

.pricing .priceSection {
  border: 1px solid #2A2A2C !important; 
  border-radius: 12px;
  padding: 16px 16px 12px 16px;
  width: -webkit-fill-available;
  background-color: #0A0A0A;
}

.pricing .priceSection h3 {
color: #ff5f00;
}

.pricing .current {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  padding: 20px 0px;
  border-radius: 10px;
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.25), 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  
}


.pricing .column:hover {
  background-color: #000;
}
.pricing .container .column .top {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 24px;
  padding-bottom: 40px;
}

.pricing .container .column .bottom {
  display: flex;
  padding-top: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
  padding-bottom: 40px;
}

.pricing .container .column .bottom .included {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
}

.pricing .container .column .bottom .included img {
  width: 12px;
  height: 12px;
}

.pricing .column .button-container {
  margin-top: auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;  /* Adjust this to center or flex-end if you want to change button alignment */
}


.accordion {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #2A2A2C;
}

.accordion-title {
  padding: 15px;
  cursor: pointer;
  background: #f5f5f5;
  border-bottom: 1px solid #2a2a2c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}

.accordion-content {
  padding: 15px;
  background: #0A0A0A;
  border-bottom: 1px solid #2a2a2c;
}

.accordion-title:last-child {
  border-bottom: none;
}


.freeTier {
  user-select: none;
  cursor: default;
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 40px;
  z-index: 10;
  left: 50%; /* Center horizontally */
  transform: translate(-50%, -50%);
  width: fit-content;
  border-radius: 10px;
  background-color: #0A0A0A;
  border: 1px solid #2A2A2C;
  padding: 8px 14px;
  gap: 8px;
  
}

.freeTier p {
  font-size: 14px;
  font-weight: 500;
}

.freeTier a {
  color: #ff5f00;
}

.freeTier.exceeded a {
  color: red;
}

.freeTier .icon {
  background-color: #ff5f00;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.freeTier.exceeded .icon {
background-color: red;
}

.freeTier .icon p {
  font-size: 12px;
  font-weight: 900;
  line-height: 20px;
  color: #f9f9f9;
  text-align: center;
}




.paidPlanBadge {
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  border-radius: 10px;
  background-color: #0A0A0A;
  border: 1px solid #ff5f00;
  color: #ff5f00 !important;
  padding: 2px 7.5px;
  margin: 8px;
}

.paidPlanBadgeContainer {
  display: flex;
 align-items: center;
}


#integryContainer {
  background-color: #111111 !important;
  margin-top: 12px;
  max-width: 100% !important;
  margin-bottom: 64px;
}

#integryContainer * {
  font-family: inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

#stepsWrap {
  max-height: 390px !important;
}

#stepInfo::after {
  content: "To display your MagicForm fields here, we use the submission with the most fields from the last 30. Fill out all fields and submit the form once before mapping below.";
  display: block;
  color: #ff5f00;
  font-family: inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 140%;
  font-size: 12px;
  font-weight: 400;
  margin: 10px 0 20px 0;
}

#integryContainer a {
  color: #ff5f00 !important;
}

fieldset,
input,
#tag-menu-body-item div {
  color: #FFFFFF !important;
}

.styles-module_appsForFlowsCards__2npJU {
  padding: 0px !important;
  margin: 0px !important
}

.styles-module_appsHeaderTitle__1o_Cb, .styles-module_embedPreviewFooter__26Gb3 {
  display: none!important;
}

.styles-module_searchContainer__3PALr {
  margin: 0px !important;
  margin-bottom: 12px !important;
}

.styles-module_appsContainerInline__86Sau .styles-module_appsHeader__3eU5R .styles-module_searchContainer__3PALr {
  width: calc(100% - 25px) !important;
}


.styles-module_appCard__3kLxI, #search-container, .styles-module_flowCard__prxMM, .styles-module_accountFieldDropdown__3Z6Lw, .styles-module_appsHeader__3eU5R .styles-module_searchContainer__3PALr input, .styles-module_inputWrapper__1XBHY input, .styles-module_mappingOption__kw6LK .styles-module_field__1jgKX {
  background-color: #0A0A0A !important;
  border-color: #2A2A2C!important;
}

.tagify {
  border-color: #2A2A2C!important;
  border-radius: 4px !important;
  --tags-focus-border-color: blue!important;
}

.styles-module_appPageContainer__1GSdZ integry-container__app-page styles-module_appPageContainerCompact__2Sqpi {
  max-width: 1200px !important;
}

.styles-module_appPageContainer__1GSdZ .styles-module_header__2UaQu, .styles-module_appPageContainer__1GSdZ .styles-module_headerCompact__3MfLR {
  border-bottom-color: #2A2A2C!important;
}

.styles-module_appsContainerInline__86Sau, #appPageContainer-COMFORTABLE--INLINE {
  border: 0px !important; 
}
    
.styles-module_button__osBYJ.styles-module_btnPrimary__2lzrP {
  border-color: #ff5f00 !important;
  background: #ff5f00 !important;
}

.styles-module_multipurposeFieldContainer__a_bjy .styles-module_multipurposeField___d1hk tags {
  --tag-hover: #ff5f00 !important;
  --tag-bg:  #ff5f00 !important;
  --tag-remove-bg:  #ff5f00 !important;
}

.styles-module_searchInput__1AUmQ, .styles-module_appName__1_3Zr, .styles-module_flowSetupHeaderCompact__12J0Q .styles-module_flowNameCompact__2JAOQ span, .tagify--empty .tagify__input::before, .styles-module_mappingOption__kw6LK .styles-module_field__1jgKX .styles-module_fieldValue__1Sdog {
 
  color: white !important;
}


.styles-module_appsContainerInline__86Sau .styles-module_appsForFlowsCards__2npJU {
  width: 100%!important;
}

.styles-module_preact-hint__2fj-j {
  z-index: 10 !important;
}

.styles-module_appsContainerInline__86Sau .styles-module_appsForFlowsCards__2npJU {
  overflow: visible;
}

.styles-module_appCard__3kLxI span, .styles-module_flowCard__prxMM .styles-module_flowInfo__od-hk .styles-module_flowName__fQw9K, .styles-module_accountField__3lkJb p, .styles-module_stepsWrap__aRbNC .styles-module_stepInfo__3EeR7 .styles-module_header__1LU3O .styles-module_name__gDntC, .styles-module_label__PbFlo .styles-module_subLabel__1wZFE > div:first-child, .styles-module_label__PbFlo .styles-module_required__1XFqi {
  color: white!important;
}

.styles-module_stepsWrap__aRbNC .styles-module_stepContainer__1Q7-E, .styles-module_stepsWrap__aRbNC .styles-module_stepContainer__1Q7-E .styles-module_save__1KXBX {
  min-height: unset !important;
}

.styles-module_flowCard__prxMM {
  min-height: 200px !important;
}

.styles-module_appsContainerInline__86Sau {
  max-width: unset !important;
}

.styles-module_appCard__3kLxI .styles-module_appCardAuthStatus__ePbqm, .tagify--empty .tagify__input::before  {
  color: #9f9f9f!important;
}

.styles-module_authSelectorDropdownContainer__3XT_o .styles-module_rowSelected__-87Wu {
  background: #111111!important;
}

.styles-module_authWrapDropdown__3i-mL, .styles-module_tagMenu__1zL3Y, .styles-module_listbox__1E5tZ .styles-module_customSelectTrigger__3iJvU .styles-module_searchInput__3_5bE, .styles-module_collapsedMenu__2VOeA .styles-module_popup__2szB6 .styles-module_optionWrap__QnebV {
  background: #111111 !important;
  border-color: #2A2A2C!important;
}

.styles-module_tagMenu__1zL3Y, .styles-module_collapsedMenu__2VOeA .styles-module_popup__2szB6 .styles-module_optionWrap__QnebV .styles-module_option__310WQ:hover {
  background: #0A0A0A !important;
  
}

.styles-module_authSelectorDropdownContainer__3XT_o .styles-module_addAccount__2QOF4 span {
  color: #ff5f00 !important;
  border-bottom: 0px !important;
}

input:checked + .styles-module_slider__2cwDH {
  background-color:  #ff5f0050 !important;;
}

input:checked + .styles-module_slider__2cwDH:before {
  background-color:  #ff5f00 !important;;
}

.styles-module_authSelectorDropdownContainer__3XT_o .styles-module_addAccount__2QOF4:hover {
  background: #111111 !important;
}

.styles-module_listbox__1E5tZ .styles-module_customSelectTrigger__3iJvU {
  border-color: #2A2A2C!important; 
}
  
.styles-module_bottom__1Z1M1, .styles-module_singleFlowInstanceRow__3eoya {
  border-top-color: #2A2A2C!important; 
}




@media (max-width: 768px) {
  .signupBox, .signupInput {
    width: 100%;
    flex: 1 0 auto;
  }

  

  .framerComponent, .getStarted {
    flex-basis: 100%; /* Each takes full width */
  }
}




/* Further adjustments for mobile */
@media (max-width: 480px) {
  .newFormOnboarding {
    flex-direction: column; /* Stack vertically on smaller screens */
  }
}


